	
	import { mapGetters } from 'vuex'

	import moment from 'moment'

	import Notification from '@/modules/notification/views/notificationList'
	import Breadcrumb from '@/components/breadcrumb/index.vue'

	export default{
		name: "Aside",
		components: {
			Notification,
			Breadcrumb,
		},
		data(){
			return{
				visibility: 1,
				gauge:{
					id_societe: localStorage.getItem("id_societe"),
					id_exercice_cp: localStorage.getItem("id_exercice"),
					cloture: ""
				},
				societe: localStorage.getItem("name_societe") || "Societe",
				exercice: localStorage.getItem("date_exercice") || "Exercice comptable",
				clotures: [],
				cloture: localStorage.getItem("cloture") || "Date de cloture",
			}
		},
		methods:{
			setVisibility(){
				this.visibility *=-1
			},
			async setSociete(societe){
				if(this.$route.name=="Display"){
					this.$router.push({name: "History"})
				}
				this.societe = societe.nom_societe
				this.gauge.id_societe = societe.id
				localStorage.setItem("id_societe",societe.id)
				localStorage.setItem("name_societe",societe.nom_societe)

				this.exercice = ""
				this.gauge.id_exercice = ""
				localStorage.removeItem("id_exercice",societe.id)
				localStorage.removeItem("date_exercice",this.exercice)
				this.cloture=""
				this.clotures = []

				await this.$store.dispatch("defaultStoreIndex/EXERCICE_COMPTABLE",societe.id)
			},
			setExercice(exercice){
				this.exercice = "De "+exercice.date_debut+" à "+exercice.date_cloture
				this.gauge.id_exercice_cp = exercice.id
				
				localStorage.setItem("id_exercice",exercice.id)
				localStorage.setItem("date_exercice",this.exercice)

				this.setDeadLine(exercice.date_debut,exercice.date_cloture)
			},

			setDeadLine(start,end){
				let startdate = moment(start, "YYYY-MM-DD", true)
				let deadline = moment(end, "YYYY-MM-DD", true)
				
				this.clotures = []

				this.clotures.push(startdate.format("YYYY-MM-DD",true))
				
				while(startdate.format("MM-YYYY")!=deadline.format("MM-YYYY")){
					startdate = startdate.add(1,'months').endOf('months')
					this.clotures.push(startdate.format("YYYY-MM-DD",true))
				}
			},

			async setCloture(cloture){
				this.cloture = cloture
				this.gauge.cloture = cloture
			},
			LOGOUT(){
				this.$store.dispatch("authStoreIndex/LOGOUT")
				setTimeout(function(){
					this.$router.push({name: "Home"})
				}.bind(this), 3000)
			},
		},
		computed: mapGetters({
			societes: "defaultStoreIndex/SOCIETE",
			exercice_comptables: "defaultStoreIndex/EXERCICE_COMPTABLE",
		}),
		created(){
			this.$store.dispatch("defaultStoreIndex/SOCIETE")
		},
		watch:{
			async societes(){
				this.setSociete(this.societes[0])
			},
			async exercice_comptables(){
				if(this.exercice_comptables.length>0){
					await this.setExercice(this.exercice_comptables[0])
					await this.setCloture(this.clotures[this.clotures.length-1])
				}
			},

		}
	}